import React, { useEffect, useState } from 'react';
import {
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  SimpleGrid,
  VStack,
  Box,
  Image,
  Switch,
  FormLabel,
  Checkbox,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Modal,
  ModalFooter,
  Button,
  ModalBody,
  FormControl,
  Input,
  Divider,
} from '@chakra-ui/react';

import { AuthenticatedLayout } from '../layouts';
import { useSearchParams } from 'react-router-dom';
import { useAppointmentActions, useUserActions } from '../hooks';

import { Appointment, Appointments, Therapist } from '../types';
import { useTherapistActions } from '../hooks/therapist-actions';
import { AppointmentList } from '../components/appointments/AppointmentList';
import { TherapistCard } from '../components/therapists/TherapistCard';
import {
  calculateMinutesBetweenDates,
  formatDateStringWithWeekdayInSwedish,
  formatTimeString,
} from '../helpers/dateFormatting';
import { usePaymentActions } from '../hooks/payment-actions';
import { NextAvailableAppointmentCard } from '../components/appointments/NextAvailableAppointment';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../state';

export const SubscribePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { getTherapists } = useTherapistActions();
  const { purchaseProduct } = usePaymentActions();
  const user = useRecoilValue(currentUserState);

  const [phoneNumber, setPhoneNumber] = useState<string>(user?.phone_number || '');

  const { updateMyPhoneNumber } = useUserActions();

  const {
    getAppointments,
    previewAppointmentById,
    previewCouplesAppointmentById,
    // bookAppointment,
    getMyUpcomingAppointments,
  } = useAppointmentActions();

  const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [isBooked, setIsBooked] = useState(false);
  const [freeCard, setFreeCard] = useState(false);
  const [showCouples, setShowCouples] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<Appointments>([]);
  const [previewedAppointment, setPreviewedAppointment] = useState<Appointment | null>(null);
  const [nextAppointmentId, setNextAppointmentId] = useState<string | null>(null);
  const [bookedAppointments, setBookedAppointments] = useState<Appointments>([]);
  const navigate = useNavigate();

  const doIt = async () => {
    const allTherapists = await getTherapists();
    const allAppointments = await getAppointments();
    const upcomingAppointments = await getMyUpcomingAppointments();
    if (allTherapists) {
      setTherapists(allTherapists);
    }
    if (allAppointments) {
      setAppointments(allAppointments);
    }
    if (upcomingAppointments) {
      setBookedAppointments(upcomingAppointments);
    }
  };

  useEffect(() => {
    if (user?.user_type === 'therapist') {
      navigate('/appointments');
      return;
    }
    doIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const priceText =
    freeCard && showCouples
      ? 'Pris 200kr. Denna avgift är för din partner. Din partner måste visa upp sin legitimation under mötet, samt uppge ev frikort.'
      : !freeCard && showCouples
      ? 'Pris: 400kr. Priset anger kostnaden för dig och din partner. Din partner måste visa upp sin legitimation under mötet, samt uppge ev frikort.'
      : freeCard && !showCouples
      ? 'Pris: 0kr.'
      : 'Pris: 200kr. Högkostnadsskydd gäller.';

  const preview = async (appointmentIds: string[]) => {
    // await bookAppointment(appointmentId);
    let res;
    if (showCouples) {
      setNextAppointmentId(appointmentIds[1]);
      res = await previewCouplesAppointmentById(appointmentIds[0], appointmentIds[1]);
    } else {
      res = await previewAppointmentById(appointmentIds[0]);
    }
    if (res) {
      setPreviewedAppointment(res);
      // doIt();
    }
  };

  const book = async (appointmentId: string) => {
    setIsBooked(false);
    updateMyPhoneNumber(phoneNumber);
    const appointmentToBook = await previewAppointmentById(appointmentId);
    if (appointmentToBook && appointmentToBook.status !== 'available') {
      setIsBooked(true);
      setPreviewedAppointment(null);
      doIt();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }
    await purchaseProduct(showCouples, appointmentId, nextAppointmentId, freeCard);
    setPreviewedAppointment(null);
    setFreeCard(false);
    doIt();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <AuthenticatedLayout sidebar={false}>
      <VStack spacing={2}>
        {isBooked && (
          <Alert rounded={4} status="error">
            <AlertIcon />
            <AlertDescription>
              Den tiden du har valt har tyvärr redan blivit bokad av någon annan! Välj en ny tid.
            </AlertDescription>
          </Alert>
        )}
        {process.env.REACT_APP_STRIPE_TEST_MODE === '1' && (
          <Alert rounded={4} status="info">
            <AlertIcon />
            <AlertDescription>
              Testläge för betalningar är aktivt. Inga dragningar kommer att genomföras för att underlätta testning av
              tjänsten. Testläge för betalningar är aktivt. Inga dragningar kommer att genomföras för att underlätta
              testning av tjänsten.
            </AlertDescription>
          </Alert>
        )}
        {searchParams.get('status') === 'warning' && (
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle>Betalningen misslyckades</AlertTitle>
            {/* <AlertDescription>Something went wrong while processing your payment. Please try again.</AlertDescription> */}
          </Alert>
        )}
        {searchParams.get('status') === 'success' && (
          <Alert status="success">
            <AlertIcon />
            <AlertTitle>Betalningen lyckades</AlertTitle>
            {/* <AlertDescription>Something went wrong while processing your payment. Please try again.</AlertDescription> */}
          </Alert>
        )}
      </VStack>

      <VStack spacing={4} align="flex-start" mt={8}>
        {Object.keys(bookedAppointments).length > 0 ? (
          <Box w={'100%'} pb={24} pt={12}>
            <Text fontSize="xl" fontWeight="bold">
              Kommande bokningar
            </Text>
            <AppointmentList upcoming handleClick={() => null} appointmentsByDay={bookedAppointments} />
          </Box>
        ) : (
          <Box w={'full'}>
            <NextAvailableAppointmentCard loggedIn={true} handleBook={(id: string) => preview([id])} />
          </Box>
        )}
        <Text fontSize="xl" fontWeight="bold">
          Sexologer
        </Text>
        <SimpleGrid minChildWidth="275px" width="100%" spacingX="20px" spacingY="20px">
          {therapists?.map((therapist) => (
            <TherapistCard key={therapist.id} therapist={therapist} />
          ))}
        </SimpleGrid>
        {/* <TopicsList /> */}

        <Text pt={12} fontSize="xl" fontWeight="bold">
          Lediga tider
        </Text>
        <Box>
          Oavsett din sökorsak finns möjlighet att möta sexolog i enskilt samtal eller tillsammans med din partner.
        </Box>
        <Box display="flex" alignItems="center">
          <FormLabel htmlFor="par-switch" mb="0">
            Visa tider för parsamtal
          </FormLabel>
          <Switch onChange={(e) => setShowCouples(e.target.checked)} id="par-switch" size="lg" color="purple" />
        </Box>
        <Box width="100%">
          {Object.keys(appointments).length > 0 && (
            <AppointmentList handleClick={preview} showCouples={showCouples} appointmentsByDay={appointments} />
          )}
        </Box>
      </VStack>
      {previewedAppointment && previewedAppointment.therapist && (
        <Modal
          isOpen={!!previewedAppointment}
          onClose={() => {
            setPreviewedAppointment(null);
            setNextAppointmentId(null);
            setFreeCard(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box p={4}>
                <Box display="flex" alignItems={'center'} pb={4}>
                  <Image
                    boxSize="75px"
                    objectFit="cover"
                    borderRadius="full"
                    src={`/therapists/therapist-${previewedAppointment?.therapist?.id}.jpeg`}
                    alt={previewedAppointment?.therapist.first_name}
                  />
                  <Text ml={4} fontSize="xl" fontWeight="bold">
                    {previewedAppointment?.therapist.first_name + ' ' + previewedAppointment?.therapist.last_name}
                  </Text>
                </Box>
                <Text fontSize="sm" color="gray.600">
                  {previewedAppointment?.therapist?.description}
                </Text>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box>
                    <Text pt={4}>{formatDateStringWithWeekdayInSwedish(previewedAppointment?.start_time)}</Text>
                    <Text>{formatTimeString(previewedAppointment?.start_time)}</Text>
                    <Text>
                      {calculateMinutesBetweenDates(previewedAppointment?.start_time, previewedAppointment?.end_time)}
                    </Text>
                  </Box>
                </Box>
                <Divider pt={2}></Divider>
                <Box pt={4}>
                  <Text pt={2} color="gray.600" fontSize="sm">
                    Genom att du fyller i ditt mobilnummer godkänner du att CharlieCare skickar avisering via sms till
                    dig gällande bokat besök.
                  </Text>
                  <FormControl mt={4}>
                    <Text>Telefonnummer</Text>
                    <Input onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}></Input>
                  </FormControl>
                  <Divider py={4}></Divider>
                  <Text>{priceText}</Text>
                  <Checkbox pt={2} checked={freeCard} onChange={() => setFreeCard(!freeCard)} colorScheme={'purple'}>
                    Jag har frikort
                  </Checkbox>
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="ghost"
                onClick={() => {
                  setPreviewedAppointment(null);
                  setNextAppointmentId(null);
                  setFreeCard(false);
                }}
              >
                Avbryt
              </Button>
              <Button
                colorScheme={'purple'}
                ml={3}
                onClick={() => book(previewedAppointment.id)}
                isDisabled={phoneNumber.length < 8} // Disable the button if the condition is met
              >
                Boka
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </AuthenticatedLayout>
  );
};
