import { Box, Button, Text, SimpleGrid, Heading } from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SoftCard } from '../generic/SoftCard';

const Collaborators = () => {
  const collaborators = [
    {
      name: 'Health Clinic',
      url: 'https://healthclinic.se/',
      description: 'Läkare med kunskap om sexuell hälsa',
    },
    {
      name: 'Lustly',
      url: 'https://lustly.se/',
      description: 'Hjälpmedel för din sexuella hälsa',
    },
  ];

  return (
    <>
      <SimpleGrid minChildWidth="300px" width="100%" spacingX="20px" spacingY="20px">
        {collaborators.map((collaborator) => (
          <SoftCard
            key={collaborator.name}
            cursor="pointer"
            style={{
              minHeight: '140px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            textAlign="center"
          >
            <Box>
              <Heading textAlign={'center'} textColor="#24084F" size="md" pb={4} alignItems="center">
                {collaborator.name}
              </Heading>
              <Text textColor="#24084F">{collaborator.description}</Text>
            </Box>
            <Link to={collaborator.url}>
              <Button
                mt={4}
                justifyContent={'center'}
                display="flex"
                gap={2}
                width={'100%'}
                borderRadius={'8px'}
                border="2px solid #ECE9FB"
                backgroundColor={'purple.50'}
                _hover={{
                  backgroundColor: 'purple.100',
                }}
                fontWeight="semibold"
                textColor="#5E13D8"
                alignItems={'center'}
              >
                <Text>Läs mer</Text>
                <FaExternalLinkAlt />
              </Button>
            </Link>
          </SoftCard>
        ))}
      </SimpleGrid>
    </>
  );
};

export default Collaborators;
