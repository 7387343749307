import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, SimpleGrid, Text, Box, VStack, IconButton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Appointments } from '../../types';
import { SoftCard } from '../generic/SoftCard';

const formatDateString = (dateString: Date): string => {
  let date = new Date(dateString);

  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed in JS
  let day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

const formatTimeString = (dateString: Date): string => {
  let date = new Date(dateString);

  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

const formatDateStringWithWeekdayInSwedish = (dateString: Date): string => {
  let date = new Date(dateString);

  let weekdayArray = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
  let weekday = weekdayArray[date.getDay()];

  let month = (date.getMonth() + 1).toString(); // months are 0-indexed in JS
  let day = date.getDate().toString();

  return `${weekday}, ${day}/${month}`;
};

const generateContinuousDates = (startDate: Date, days: number): string[] => {
  const result = [];
  for (let i = 0; i < days; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    result.push(currentDate.toISOString().split('T')[0]);
  }
  return result;
};

const calculateMinutesBetweenDates = (startDate: Date, endDate: Date): string => {
  let start = new Date(startDate);
  let end = new Date(endDate);

  let differenceInMilliseconds = end.getTime() - start.getTime();
  let differenceInMinutes = Math.abs(Math.floor(differenceInMilliseconds / (1000 * 60)));

  return `${differenceInMinutes} min`;
};

const isLessThanFiveMinutesIntoTheFuture = (startDate: Date): boolean => {
  let start = new Date(startDate);
  let now = new Date();

  let differenceInMilliseconds = start.getTime() - now.getTime();
  let differenceInMinutes = differenceInMilliseconds / (1000 * 60);

  return differenceInMinutes < 5;
};

export const AppointmentList = ({
  upcoming = false,
  appointmentsByDay,
  handleClick,
  showCouples,
  therapist,
}: {
  upcoming?: boolean;
  appointmentsByDay: Appointments;
  handleClick: Function;
  showCouples?: boolean;
  therapist?: boolean;
}) => {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const stepSize = window.innerWidth > 768 ? 4 : 1; // Number of days to show at once

  const handleNext = () => {
    setCurrentStartIndex((prevIndex) => prevIndex + stepSize);
  };

  const handlePrev = () => {
    setCurrentStartIndex((prevIndex) => Math.max(prevIndex - stepSize, 0));
  };

  const allDates = generateContinuousDates(new Date(), 60);

  const combinedAppointments = allDates.map((date) => {
    const matchedDay = appointmentsByDay.find((d) => d.date === date);
    if (matchedDay) {
      return matchedDay;
    } else {
      return { date, appointments: [] };
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setForceUpdate((prev) => !prev);
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const visibleDays = combinedAppointments.slice(currentStartIndex, currentStartIndex + stepSize);

  return upcoming ? (
    <VStack spacing={4}>
      {appointmentsByDay?.map(({ date, appointments }) =>
        appointments.map((appointment, index) => (
          <SoftCard key={appointment.id}>
            <Box display={'flex'} justifyContent="space-between">
              <Box>
                <Text>{appointment.description}</Text>
                <Text>
                  {formatDateStringWithWeekdayInSwedish(appointment.start_time)} kl.{' '}
                  {formatTimeString(appointment.start_time)} (
                  {calculateMinutesBetweenDates(appointment.start_time, appointment.end_time)})
                </Text>
                {!therapist && (
                  <Text color="gray">Om du upplever situationen som akut bör du kontakta sjukvården.</Text>
                )}
                {!!therapist && <Text color={'gray'}>{appointment?.patient?.personal_number}</Text>}
                {!!therapist && appointment?.free_card && <Text color="green">Bokad med frikort</Text>}
                {!!therapist && appointment?.patient?.email && (
                  <Text color={'gray'}>{appointment?.patient?.email}</Text>
                )}
                {!!therapist && appointment?.patient?.phone_number && (
                  <Text color={'gray'}>{appointment?.patient?.phone_number}</Text>
                )}
              </Box>
              {isLessThanFiveMinutesIntoTheFuture(appointment.start_time) ? (
                <Link to={`/meeting/${appointment.id}`}>
                  <Button>Gå till mötet</Button>
                </Link>
              ) : (
                <Button isDisabled={true}>Gå till mötet</Button>
              )}
            </Box>
          </SoftCard>
        ))
      )}
    </VStack>
  ) : (
    <>
      <Box display="flex" justifyContent="space-between" mt="2" width="100%">
        <IconButton
          bgColor={'white'}
          icon={<ArrowBackIcon />}
          onClick={handlePrev}
          isDisabled={currentStartIndex === 0}
          aria-label="Längre bak"
        />
        <IconButton
          bgColor={'white'}
          icon={<ArrowForwardIcon />}
          isDisabled={currentStartIndex >= combinedAppointments.length - stepSize}
          onClick={handleNext}
          aria-label="Längre fram"
        />
      </Box>
      <SimpleGrid minHeight={400} columns={[stepSize]} spacing={4}>
        {visibleDays?.map(({ date, appointments }) => (
          <Box backgroundColor={'white'} borderRadius={'16px'} p={4} key={date} my={5} textAlign="center">
            <Text fontSize={'sm'} mb={4} fontWeight="bold">
              {formatDateStringWithWeekdayInSwedish(new Date(date))}
            </Text>
            <SimpleGrid columns={[1]} spacing={2}>
              {appointments?.map((x, index) => {
                if (
                  showCouples &&
                  index + 1 < appointments.length &&
                  appointments[index + 1].status === 'available' &&
                  x.end_time === appointments[index + 1].start_time &&
                  x.therapist_id === appointments[index + 1].therapist_id
                ) {
                  return (
                    <SoftCard
                      button
                      p={6}
                      style={{ border: '2px solid #ECE9FB' }}
                      key={x.id + '-double'}
                      onClick={() => {
                        handleClick([x.id, appointments[index + 1].id]);
                      }}
                    >
                      <Box>
                        <Text fontSize={'sm'} fontWeight={'bold'}>
                          {formatDateString(x.start_time).split(' ')[1]}
                        </Text>
                        <Text fontSize={'sm'} textColor="grey" fontWeight="thin">
                          {calculateMinutesBetweenDates(x.start_time, appointments[index + 1].end_time)}
                        </Text>
                      </Box>
                    </SoftCard>
                  );
                } else if (!showCouples || (showCouples && appointments[index - 1]?.status !== 'available')) {
                  return (
                    <SoftCard
                      style={{ border: '2px solid #ECE9FB' }}
                      button
                      p={6}
                      key={x.id}
                      onClick={() => {
                        handleClick([x.id]);
                      }}
                    >
                      <Box>
                        <Text fontSize={'sm'} fontWeight={'bold'}>
                          {formatDateString(x.start_time).split(' ')[1]}
                        </Text>
                        <Text fontSize={'sm'} textColor="grey" fontWeight="thin">
                          {calculateMinutesBetweenDates(x.start_time, x.end_time)}
                        </Text>
                      </Box>
                    </SoftCard>
                  );
                }
                return null;
              })}
              {appointments.length === 0 && <Text fontSize={'sm'}>Inga lediga tider</Text>}
            </SimpleGrid>
          </Box>
        ))}
      </SimpleGrid>
    </>
  );
};
